import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { MySharedModule } from 'src/app/modules/shared/my-shared.module';
import { MyMessageService } from 'src/app/modules/shared/services/my-message.service';
import { StrongPasswordRegx } from '../../auth.module';

@Component({
	selector: 'stj-auth-recupera-senha',
	standalone: true,
	imports: [
		MySharedModule,
	],
	providers: [
		MyMessageService,
		AuthService,
	],
	templateUrl: './auth-recupera-senha.component.html',
	styleUrl: './auth-recupera-senha.component.scss'
})
export class AuthRecuperaSenhaComponent
{
	constructor(
		private authService: AuthService,
		private aroute: ActivatedRoute,
		private router: Router,
		private msgService: MyMessageService,
	)
	{
		this.email = new FormControl('', [
			Validators.required,
			Validators.email,
		]);
		this.senha1 = new FormControl('', [
			Validators.required,
			Validators.minLength(8),
			Validators.pattern(StrongPasswordRegx)
		]);
		this.senha2 = new FormControl('', [
			Validators.required,
			Validators.minLength(8),
			Validators.pattern(StrongPasswordRegx)
		]);
		this.form = new FormGroup({
			email: this.email,
			senha1: this.senha1,
			senha2: this.senha2,
		})
	}

	loading: boolean = false;
	email: FormControl;
	senha1: FormControl;
	senha2: FormControl;
	form: FormGroup;

	async doPasswordReset()
	{
		this.msgService.clear();
		this.loading = true;
		let token = this.aroute.snapshot.params['token'];
		let retorno: any = {};
		try
		{
			retorno = await this.authService.resetPassword({email: this.email.value, senha: this.senha1.value, token});
			if (retorno.status)
			{
				this.msgService.add({ severity: 'success', summary: retorno.msg });
				setTimeout(() =>
				{
					this.router.navigate(['/', 'auth', 'login']);
				}, 1500);
			}
		} catch (error: any)
		{
			this.msgService.error(error);
			console.log(error);
		}
		this.loading = false;
		console.log(retorno);
		return retorno;
	}
}
