import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../../../modules/auth/services/auth.service';
import { CommonModule } from '@angular/common';
import { PrimengModule } from '../../../../modules/shared/primeng/primeng.module';
import { Subscription } from 'rxjs';
import { UserPhotoComponent } from 'src/app/areas/layout/components/user-photo/user-photo.component';

@Component({
	selector: 'stj-auth-profile-page',
	standalone: true,
	imports: [
		CommonModule,
		PrimengModule,
		UserPhotoComponent,
	],
	templateUrl: './auth-profile-page.component.html',
	styleUrl: './auth-profile-page.component.scss'
})
export class AuthProfilePageComponent implements OnInit, OnDestroy
{
	constructor (
		private authService: AuthService,
	)
	{ }

	roles?: any[] = [];
	msRoles?: any[] = [];
	layout: "list" | "grid" = 'grid';
	Object: any = Object;
	subs: { [key: string]: Subscription } = {};

	async ngOnInit(): Promise<void>
	{
		this.subs['roles'] = this.authService.roles.subscribe(mixedRoles =>
		{
			console.log(mixedRoles)
			this.roles = [];
			this.msRoles = [];
			if (mixedRoles.msRoles?.length == 0) return;

			for (let role of mixedRoles.roles ?? [])
			{
				this.roles?.push(role);
			}
			this.msRoles = mixedRoles.msRoles ?? [];
		})
		// let profile = await this.authService.getFullProfile();
		this.authService.photo.next(await this.authService.getPhoto());
		this.authService.profile.next(await this.authService.getFullProfile());
		this.authService.roles.next(await this.authService.getMyRoles());
		// this.authService.myRoles.subscribe((roles: any) => this.roles = roles);
	}

	ngOnDestroy(): void
	{
		for (let s in this.subs)
		{
			let sub = this.subs[s];
			sub.unsubscribe();
		}
	}
}
