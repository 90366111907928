import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { UserPhotoComponent } from '../layout/components/user-photo/user-photo.component';
import { PrimengModule } from 'src/app/modules/shared/primeng/primeng.module';
import { AuthRecuperaSenhaComponent } from './components/auth-recupera-senha/auth-recupera-senha.component';
import { AuthPasswordComponent } from './components/auth-password/auth-password.component';
import { AuthProfilePageComponent } from './components/auth-profile-page/auth-profile-page.component';
import { authGuard } from 'src/app/modules/auth/guards/auth.guard';
import { AuthLoginPageComponent } from './components/auth-login-page/auth-login-page.component';
import { AuthInterceptor } from 'src/app/modules/auth/auth.interceptor';
import { MyMessageService } from 'src/app/modules/shared/services/my-message.service';
import { MyCookieService } from 'src/app/modules/auth/services/my-cookie.service';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
export const moduleRoutes: Route[] = [
	{ path: '', pathMatch: 'full', redirectTo: 'profile' },
	// { path: '', pathMatch: 'full', redirectTo: 'profile' },
	{ path: 'recupera-senha/:token', component: AuthRecuperaSenhaComponent },
	{ path: 'muda-senha', component: AuthPasswordComponent, canActivate: [authGuard] },
	{ path: 'profile', component: AuthProfilePageComponent, canActivate: [authGuard] },
	{ path: ':strategy', pathMatch: 'full', component: AuthLoginPageComponent },
	{ path: ':strategy/:action', pathMatch: 'full', component: AuthLoginPageComponent },
	{ path: ':strategy/:action/:extra', component: AuthLoginPageComponent },
	{ path: '**', pathMatch: 'full', redirectTo: '/error/404' },
	// {
	// 	path: '', pathMatch: 'prefix', children: [
	// 	]
	// },
]

export const StrongPasswordRegx: RegExp = /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d)(?=.*[!@#$%^&*]).{8,}$/;

@NgModule({
	declarations: [
	],
	imports: [
		UserPhotoComponent,
		CommonModule,
		// MessageModule,
		// MessagesModule,
		PrimengModule,
		// RouterModule.forChild(moduleRoutes),
	],
	providers: [
		AuthInterceptor,
		CookieService,
		MyMessageService,
		MyCookieService,
		AuthService,
		MyMessageService,
	],
	exports: [
	]
})
export class AuthModule { }
