import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Message } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../../../../modules/auth/services/auth.service';
import { MySharedModule } from '../../../../modules/shared/my-shared.module';
import { MyMessageService } from '../../../../modules/shared/services/my-message.service';
import hmacsha1 from 'hmacsha1';
const Encoder = require('../../../../libs/encoder');

@Component({
	selector: 'stj-auth-login-page',
	standalone: true,
	imports: [
		MySharedModule,
	],
	providers: [
		AuthService,
		MyMessageService,
	],
	templateUrl: './auth-login-page.component.html',
	styleUrl: './auth-login-page.component.scss'
})
export class AuthLoginPageComponent implements OnInit
{
	constructor (
		@Inject(ActivatedRoute) private readonly route: ActivatedRoute,
		@Inject(Router) private readonly router: Router,
		private authService: AuthService,
		private msgService: MyMessageService,
	)
	{
	}

	loading: boolean = true;
	loadingManual: boolean = false;
	isManualLogin: boolean = true;
	isPasswordReset: boolean = false;

	email?: string;
	senha?: string;

	user: BehaviorSubject<any> = new BehaviorSubject(undefined);
	errorMsgs: BehaviorSubject<Message[]> = new BehaviorSubject<Message[]>([]);
	public get errorMsgsStatic(): Message[]
	{
		return this.errorMsgs.value;
	}
	public set errorMsgsStatic(value: Message[])
	{
		this.errorMsgs.next(value)
	}

	async ngOnInit() 
	{
		this.errorMsgs.next([]);

		await this.route.params.subscribe(async (params: any) => 
		{
			let queryParams: any;
			await this.route.queryParams.subscribe(async (params: any) => { queryParams = params; });
			// alert(queryParams);

			if (params.strategy == 'logout')
			{
				await this.authService.localLogout();
				this.router.navigate(['/']);
				return;
			}
			if (params.strategy == 'login')
			{
				if (params.action == 'token')
				{
					this.loading = true;
					let tempToken = queryParams.token;
					let userId = queryParams.uid;

					let session: any;
					try
					{
						session = await this.authService.getFullToken(tempToken, userId);
						this.router.navigate(['/'], { replaceUrl: true });
						return;
					}
					catch (err: any) 
					{
						console.log(err.error?.message);
						let msg = { severity: 'error', summary: 'Houve um erro!', detail: err.error?.message }
						this.pushError(msg);
						this.msgService.add({ ...msg, closable: true })
						this.loading = false;
						return;
					}

				}
				else if (params.action == 'error')
				{
					let statusCode = queryParams.status;
					let message = queryParams.message;
					let msg = { severity: 'error', summary: statusCode, detail: message }
					this.pushError(msg);
					this.msgService.add({ ...msg, closable: true })
				}
				else if (params.action == 'pass')
				{
					let email = queryParams.email;
					let senha = queryParams.senha;

					let passwd = hmacsha1(email, senha);

					console.log(passwd);
					this.loading = false;
					return;
				}
				else
				{
					this.loading = false;
					// this.router.navigate(['/']);
					return;
				}
			}
		});
	}

	pushError(errorMessage: Message)
	{
		this.errorMsgs.next([...this.errorMsgs.value, errorMessage]);
	}

	async doManualLogin()
	{
		if (!this.email || !this.senha) return;

		this.loadingManual = true;
		let resultado: {
			status: boolean,
			msg: string,
			data?: any;
		};
		try
		{
			// let senhaHash = hmacsha1(this.email, this.senha);
			let senhaHash = Encoder.encrypt(this.senha);
			resultado = await this.authService.loginManual(this.email, senhaHash);
			// console.log(resultado);

			this.loadingManual = false;
			if (!resultado?.status)
			{
				let msg: Message = { severity: 'warn', summary: 'Houve um erro ao efetuar o login!', detail: resultado?.msg };
				this.msgService.add(msg);
				this.pushError(msg);
			}
			else
			{

				let user = resultado.data;
				if (!user)
				{
					let msg = { severity: 'error', summary: 'Houve um erro ao recuperar o login!', detail: 'Não foi possível recuperar os dados do usuário após o login.' };
					this.msgService.add(msg);
				}
				else
				{
					let msg: Message = { severity: 'success', summary: 'Login efetuado!', detail: resultado?.msg };
					console.log(user.tempToken);
					this.msgService.add(msg);
					setTimeout(() =>
					{
						this.router.navigate(['/auth/login', 'token'], { queryParams: { token: user?.tempToken, uid: user?.uid } });
					}, 500);
				}
				console.log(resultado);
				// this.router.navigate(['/']);
			}
		} catch (error: any)
		{
			let msg: Message = { severity: 'error', summary: 'Houve um erro ao efetuar o login!', detail: error.error ? error.error.message : error.message };
			this.msgService.add(msg);
			this.pushError(msg);
			console.log(error);
		} finally
		{
			this.loadingManual = false;
		}
	}

	async doAzureLogin()
	{
		await this.authService.login();
	}

	async doPasswordReset()
	{
		if (!this.email) return;
		this.loadingManual = true;
		try
		{
			let res = await this.authService.sendResetPassword(this.email);
			console.log(res);
			this.msgService.add({ severity: res.status ? 'success' : 'error', detail: res.msg });
		} catch (error: any)
		{
			this.msgService.error(error);
		} finally
		{
			this.loadingManual = false;
		}
	}

	openPasswordReset()
	{
		this.isPasswordReset = true;
	}
}
