import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { MyMessageService } from 'src/app/modules/shared/services/my-message.service';
import { StrongPasswordRegx } from '../../auth.module';
import { MySharedModule } from 'src/app/modules/shared/my-shared.module';

@Component({
	selector: 'stj-auth-password',
	standalone: true,
	imports: [
		MySharedModule
	],
	providers: [
		MyMessageService,
		AuthService,
	],
	templateUrl: './auth-password.component.html',
	styleUrl: './auth-password.component.scss'
})
export class AuthPasswordComponent
{
	constructor(
		private authService: AuthService,
		private router: Router,
		private msgService: MyMessageService,
	)
	{
		this.senha0 = new FormControl('', [
			Validators.required,
			Validators.minLength(8),
			// Validators.pattern(StrongPasswordRegx)
		]);
		this.senha1 = new FormControl('', [
			Validators.required,
			Validators.minLength(8),
			Validators.pattern(StrongPasswordRegx)
		]);
		this.senha2 = new FormControl('', [
			Validators.required,
			Validators.minLength(8),
			Validators.pattern(StrongPasswordRegx)
		]);
		this.form = new FormGroup({
			senha0: this.senha0,
			senha1: this.senha1,
			senha2: this.senha2,
		})
	}

	loading: boolean = false;
	senha0: FormControl;
	senha1: FormControl;
	senha2: FormControl;
	form: FormGroup;

	async doPasswordReset()
	{
		this.msgService.clear();
		this.loading = true;
		
		let user = this.authService.currentUser.value;

		if (!user?.email)
		{
			this.msgService.add({ severity: 'error', summary: 'Não foi possível encontrar o usuário atual!'});
			return;
		}

		let retorno: any = {};
		try
		{
			retorno = await this.authService.resetPassword({email: user.email, senha: this.senha1.value, senhaAnterior: this.senha0.value});
			if (retorno.status)
			{
				this.msgService.add({ severity: 'success', summary: retorno.msg });
				this.form.setValue({
					senha0: '',
					senha1: '',
					senha2: '',
				});
				this.form.markAsPristine();
			}
		} catch (error: any)
		{
			this.msgService.error(error);
		}
		this.loading = false;
		return retorno;
	}
}
