<p-panel header="Meu perfil" styleClass="m-3">
	<div class="grid">
		<div class="col-4">
			<stj-user-photo size="large" styleClass="mr-2"></stj-user-photo>
		</div>
	</div>
</p-panel>

<p-panel header="Minhas permissões no sistema" styleClass="m-3">
	<p-dataView #dv [value]="roles" layout="grid">
		<ng-template pTemplate="header">

			<div class="flex justify-content-end">
				<p-dataViewLayoutOptions default="grid"></p-dataViewLayoutOptions>
			</div>
		</ng-template>
		<ng-template let-items pTemplate="list">
			<div class="grid grid-nogutter">
				<div class="col-12" *ngFor="let item of items; let first = first">
					<div class="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4" [ngClass]="{ 'border-top-1 surface-border': !first }">
						<ng-template *ngTemplateOutlet="itemRole; context: {$implicit: item}"></ng-template>
					</div>
				</div>
			</div>
		</ng-template>
		<ng-template let-items pTemplate="grid">
			<div class="grid grid-nogutter">
				<div class="col-12 sm:col-6 lg:col-4 xl:col-3 p-2" *ngFor="let item of items">
					<ng-template *ngTemplateOutlet="itemRole; context: {$implicit: item}"></ng-template>
				</div>
			</div>
		</ng-template>
	</p-dataView>
</p-panel>

<p-panel header="Minhas permissões gerais" styleClass="m-3">
	<p-dataView #dv [value]="msRoles" layout="grid">
		<ng-template pTemplate="header">

			<div class="flex justify-content-end">
				<p-dataViewLayoutOptions default="grid"></p-dataViewLayoutOptions>
			</div>
		</ng-template>
		<ng-template let-items pTemplate="list">
			<div class="grid grid-nogutter">
				<div class="col-12" *ngFor="let item of items; let first = first">
					<div class="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4" [ngClass]="{ 'border-top-1 surface-border': !first }">
						<ng-template *ngTemplateOutlet="itemMsRole; context: {$implicit: item}"></ng-template>
					</div>
				</div>
			</div>
		</ng-template>
		<ng-template let-items pTemplate="grid">
			<div class="grid grid-nogutter">
				<div class="col-12 sm:col-6 lg:col-4 xl:col-3 p-2" *ngFor="let item of items">
					<ng-template *ngTemplateOutlet="itemMsRole; context: {$implicit: item}"></ng-template>
				</div>
			</div>
		</ng-template>
	</p-dataView>
</p-panel>
<ng-template #itemMsRole let-item>
	<p-card [header]="item.displayName" [subheader]="item.description" [styleClass]="'card-permissao'" [style]="{'width': '100%'}">
		<p><label>Criado em: </label><span>{{item.createdDateTime | date: 'short'}}</span></p>
		<p><label>Apelido: </label><span>{{item.value}}</span></p>
	</p-card>
</ng-template>
<ng-template #itemRole let-item>
	<p-card [header]="item.role?.name" [subheader]="item.role?.description" [styleClass]="'card-permissao'" [style]="{'width': '100%'}">
		<p><label>Criado em: </label><span>{{item.createdDate | date: 'short'}}</span></p>
		<p><label>Apelido: </label><span>{{item.roleId}}</span></p>
	</p-card>
</ng-template>
