<div class="m-5 flex flex-column align-items-center justify-content-center">
	<p-progressSpinner *ngIf="loading"></p-progressSpinner>
	<p-messages [(value)]="errorMsgsStatic"></p-messages>
	<p-toast></p-toast>
	<p-card header="Autenticação de Usuário" subheader="Escolha o método para login" styleClass="p-4">
		<!-- <p-button icon="pi pi-microsoft" label="Microsoft Azure AD" styleClass="login-button microsoft" (click)="doAzureLogin()"></p-button> -->
		<p-button icon="pi pi-at" label="Email" styleClass="login-button email" (click)="isManualLogin = !isManualLogin"></p-button>

		<p-fieldset legend="Insira os seus dados para acesso" *ngIf="isManualLogin && !isPasswordReset" [styleClass]="'mt-3'">
			<div class="field grid">
				<div class="col">
					<input type="email" pInputText [(ngModel)]="email" placeholder="E-mail" [disabled]="loadingManual" (keyup.enter)="doManualLogin()" />
				</div>
			</div>
			<div class="field grid">
				<div class="col">
					<input type="password" pInputText [(ngModel)]="senha" placeholder="Senha" [disabled]="loadingManual" (keyup.enter)="doManualLogin()" />
				</div>
			</div>
			<div class="field-grid">
				<div class="col">
					<p-button label="Esqueci a minha senha" icon="pi pi-question-circle" [text]="true" severity="info" (click)="openPasswordReset()"></p-button>
				</div>
			</div>
			<div class="field grid">
				<div class="col">
					<p-button
						icon="pi pi-send"
						label="Entrar"
						styleClass="login-button"
						severity="primary"
						(click)="doManualLogin()"
            (keyup.enter)="doManualLogin()"
						[loading]="loadingManual"
						type="button"
						[disabled]="loading || ((email?.length??0) < 10) || ((senha?.length??0) < 4)">
					</p-button>
				</div>
			</div>
		</p-fieldset>

		<p-fieldset legend="Reconfigurar senha de acesso" *ngIf="isManualLogin && isPasswordReset" [styleClass]="'mt-3'">
			<div class="field grid">
				<div class="col">
					<input type="email" pInputText [(ngModel)]="email" placeholder="E-mail" [disabled]="loadingManual" (keyup.enter)="doManualLogin()" />
				</div>
			</div>
			<div class="field grid">
				<div class="col">
					<p-button
						icon="pi pi-send"
						label="Enviar"
						styleClass="login-button"
						severity="primary"
						(click)="doPasswordReset()"
						(keyup.enter)="doPasswordReset()"
						[loading]="loadingManual"
						type="button"
						[disabled]="loading || ((email?.length??0) < 10)">
					</p-button>
					<p-button
						icon="pi pi-arrow-left"
						label="Cancelar"
						styleClass="login-button"
						severity="warning"
						(click)="isManualLogin = true; isPasswordReset = false;"
						[disabled]="loading || loadingManual">
					</p-button>
				</div>
			</div>
		</p-fieldset>

	</p-card>
</div>
