import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { PrimengModule } from 'src/app/modules/shared/primeng/primeng.module';
import { MSPhoto } from '../../../../types/auth.types';

@Component({
	selector: 'stj-user-photo',
	standalone: true,
	imports: [
		CommonModule,
		PrimengModule,
		HttpClientModule,
	],
	providers: [
		AuthService,
	],
	templateUrl: './user-photo.component.html',
	styleUrl: './user-photo.component.scss'
})
export class UserPhotoComponent implements OnInit, OnDestroy
{
	constructor (
		private authService: AuthService,
		private _sanitizer: DomSanitizer,
	)
	{ }

	@Input() size?: 'small' | 'thumb' | 'large' = 'thumb';

	sub?: Subscription;
	imagem?: any;
	photo?: MSPhoto;
	width: number = 300;
	height: number = 300;
	label: string = '';
	nome?: string = '';
	labelDisplay?: string;

	ngOnInit(): void
	{
		this.authService.photo.subscribe(photo =>
		{
			if (!photo) 
			{
				this.imagem = undefined;
				return;
			}
			this.width = (photo.width ?? 120) * 2;
			this.height = (photo.height ?? 120) * 2;
			let imagemUrl = photo.imagem;
			this.imagem = this._sanitizer.bypassSecurityTrustUrl(imagemUrl ?? '');
			this.labelDisplay = this.imagem ? undefined : this.label;
			// console.log(imagemUrl, this.imagem)
		});
		this.authService.profile.subscribe(profile =>
		{
			if (!profile)
			{
				this.label = '';
				this.nome = '';
				return;
			}

			this.nome = profile.displayName;
			this.label = this.nome ? this.nome[0] : '';
			this.labelDisplay = this.imagem ? undefined : this.label;
		})

	}

	ngOnDestroy(): void
	{
		if (this.sub) this.sub.unsubscribe();
	}
}
