<div class="m-5 flex flex-column align-items-center justify-content-center">
	<p-progressSpinner *ngIf="loading"></p-progressSpinner>
	<p-messages></p-messages>

	<p-fieldset legend="Mudar minha senha:" [styleClass]="'mt-3'">
		<form [formGroup]="form">
			<div class="field grid">
				<label for="senha0" class="col-fixed">Senha anterior:</label>
				<div class="col">
					<input type="password" id="senha0" pInputText formControlName="senha0" placeholder="Senha anterior" />
				</div>
			</div>
			<div class="field grid">
				<label for="senha1" class="col-fixed">Senha nova:</label>
				<div class="col">
					<input type="password" id="senha1" pInputText formControlName="senha1" placeholder="Senha nova"
						(keyup.enter)="doPasswordReset()" />
					<div class="text-red-400 text-sm" *ngIf="senha1.dirty">
						<span class="block" [ngClass]="{'text-success': senha1.value?.match('^(?=.*[A-Z])')}">
							Ao menos uma letra maiúscula.</span>
						<span class="block" [ngClass]="{'text-success': senha1.value?.match('(?=.*[a-z])')}">
							Ao menos uma letra minúscula.</span>
						<span class="block" [ngClass]="{'text-success': senha1.value?.match('(.*[0-9].*)')}">
							Pelo menos um número.</span>
						<span class="block" [ngClass]="{'text-success': senha1.value?.match('(?=.*[!@#$%^&*])')}">
							Pelo menos um caractere especial.</span>
						<span class="block" [ngClass]="{'text-success': senha1.value?.match('.{8,}')}">
							Tamanho mínimo de 8 caracteres.</span>
					</div>
				</div>
			</div>
			<div class="field grid">
				<label for="senha2" class="col-fixed">Repita a senha:</label>
				<div class="col">
					<input type="password" id="senha2" pInputText formControlName="senha2" placeholder="Repita a senha"
						(keyup.enter)="doPasswordReset()" />
					<div class="text-red-400 text-sm" *ngIf="senha2.dirty">
						<span class="block" [ngClass]="{'text-success': senha1.value == senha2.value}">
							As senhas devem ser iguais.</span>
					</div>
				</div>
			</div>
			<div class="field grid">
				<div class="col">
					<p-button icon="pi pi-send" label="Enviar" styleClass="login-button" severity="primary"
						(click)="doPasswordReset()" (keyup.enter)="doPasswordReset()" [loading]="loading" type="button"
						[disabled]="loading || (!form.valid)">
					</p-button>
				</div>
			</div>
		</form>
	</p-fieldset>
</div>